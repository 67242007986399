<script>
    import Dialog from "./Dialog.svelte";
    import { createEventDispatcher } from "svelte";
    import Translation from "./Translation.svelte";

    export let privacyUrl;
    export let imprintUrl;

    const dispatch = createEventDispatcher();

    function handleClickOnAccept() {
        dispatch("accept");
    }

    function handleClickOnDeny() {
        dispatch("deny");
    }

    function handleClickOnSettings() {
        dispatch("settings");
    }
</script>

<Dialog>
    <h2 slot="header">
        <Translation key="main_dialog_header" />
    </h2>
    <p slot="body">
        <Translation key="main_dialog_body" /><br /><br />
        <a href={imprintUrl} title="Imprint / Impressum / Mentions légales"><Translation key="main_dialog_imprint" /></a>
        <span class="text-danger">&nbsp;/&nbsp;</span>
        <a href={privacyUrl} title="Privacy / Datenschutz / Protection des données"><Translation key="main_dialog_privacy" /></a>
    </p>
    <div slot="footer" class="button-row">
        <button on:click={handleClickOnAccept} class="btn btn-outline-danger">
            <Translation key="main_dialog_accept_button" />
        </button>
        <button on:click={handleClickOnDeny} class="btn btn-outline-danger">
            <Translation key="main_dialog_deny_button" />
        </button>
        <button on:click={handleClickOnSettings} class="btn btn-outline-danger">
            <Translation key="main_dialog_settings_button" />
        </button>
    </div>
</Dialog>

<style>
    .button-row {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .button-row > button {
        margin-left: 0.5rem;
    }

    h2 {
        margin: 0;
    }
</style>
