<script>
    import { language } from "../scripts/stores.js";
    import translations from "../scripts/translations.js";
    export let key;

    let translation;

    if (translations[$language][key]) {
        translation = translations[$language][key];
    }
    else {
        translation = translations[0][key];
    }
</script>


<span>{@html translation}</span>
