<script>
    import { onMount } from "svelte";
    import Translation from "./components/Translation.svelte";
    import Cookies from "js-cookie";

    export let previewImageUrl;
    export let script = "";
    export let content;

    const cookieName = "maps";

    let agreed = false;

    function load() {
        if (Cookies.get(cookieName)) agree();
    }

    function save() {
        Cookies.set(cookieName, "agreed", {
            sameSite: "strict",
            expires: 365,
        });
    }

    function agree() {
        agreed = true;
    }

    function handleClick() {
        agree();
        save();
    }

    onMount(load);
</script>

{#if agreed}
    {@html content}
    {#if script}
        <script src={script}></script>
    {/if}
{:else}
    <div class="disabled-map">
        <img src={previewImageUrl} alt="Map" />
        <div class="overlay">
            <p>
                <Translation key="map_consent_text" />
            </p>
            <button on:click={handleClick} class="btn btn-sm btn-danger text-white">
                <div class="btn-text"><Translation key="map_consent_button" /></div>
            </button>
        </div>
    </div>
{/if}

<style>
    .disabled-map {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    .disabled-map > img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
    }

    .overlay {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.75);
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        color: #ffffff;
        padding: 1rem;
        text-align: center;
    }
</style>
