<script>
  import { language } from "./scripts/stores.js";
  import config from "./scripts/config.js";
  import multiLanguageMetaCookies from "./scripts/meta-cookies.js";
  import Cookies from "js-cookie";
  import { onMount } from "svelte";
  import Backdrop from "./components/Backdrop.svelte";
  import MainDialog from "./components/MainDialog.svelte";
  import SettingsDialog from "./components/SettingsDialog.svelte";
  import Overlay from "./components/Overlay.svelte";

  export let languageId = 0;
  export let privacyUrl = "";
  export let imprintUrl = "";
  export let minimized = false;

  $language = languageId;

  const metaCookies = multiLanguageMetaCookies[$language];

  let opened = false;
  let settingsVisible = false;

  $: optionalCookies = metaCookies.filter(
    (cookieType) => cookieType.required === false
  );

  function cookiesAreEnabled() {
    let cookiesEnabled = navigator.cookieEnabled;

    if (!cookiesEnabled) {
      Cookies.set("test");

      if (Cookies.get("test") !== undefined) {
        cookiesEnabled = true;
        Cookies.remove("test");
      } else {
        cookiesEnabled = false;
      }
    }

    return cookiesEnabled;
  }

  function preferencesExist() {
    let cookie = Cookies.get(config.preferencesCookieName);

    if (cookie !== undefined) {
      let preferences = JSON.parse(cookie);
      return preferences.version === config.version;
    }
    return false;
  }

  function open() {
    if (!minimized) {
      document.body.style.overflow = "hidden";
    }
    opened = true;
  }

  function close() {
    opened = false;
    settingsVisible = false;
    document.body.style.overflow = "auto";
  }

  function prepareMetaCookies() {
    for (let cookieType of metaCookies) {
      if (cookieType.required === false) {
        cookieType.enabled = false;

        for (let provider of cookieType.providers) {
          provider.enabled = false;
        }
      }
    }
  }

  function loadPreferences() {
    let preferences = JSON.parse(Cookies.get(config.preferencesCookieName));

    for (let cookieType of optionalCookies) {
      cookieType.enabled = (preferences[cookieType.key]) ? preferences[cookieType.key].enabled === true : false;

      for (let provider of cookieType.providers) {
        provider.enabled = (preferences[cookieType.key]) ? preferences[cookieType.key].providers[provider.key] === true : false;
      }
    }
  }

 function applyPreferences() {
  if (!optionalCookies || !Array.isArray(optionalCookies)) return; // Überprüfen, ob optionalCookies existiert und ein Array ist

  for (let cookieType of optionalCookies) {
    // Sicherstellen, dass cookieType definiert ist und die erwartete Struktur hat
    if (!cookieType || !Array.isArray(cookieType.providers)) continue;

    for (let provider of cookieType.providers) {
      // Sicherstellen, dass provider definiert ist und die erwartete Struktur hat
      if (!provider || !Array.isArray(provider.embed)) continue;

      if (cookieType.enabled || provider.enabled) {
        for (let tag of provider.embed) {
          // Sicherstellen, dass tag definiert ist und notwendige Attribute hat
          if (!tag || !tag.parent || !tag.element || !tag.content || !tag.position) continue;

          let parent = document.querySelector(tag.parent);
          
          // Überprüfen, ob das Parent-Element existiert
          if (!parent) continue;

          let element = document.createElement(tag.element);
          element.textContent = tag.content;

          // Füge das neue Element an die gewünschte Position ein, falls diese definiert ist
          if (tag.position === "start") {
            parent.insertBefore(element, parent.firstChild);
          } else if (tag.position === "end") {
            parent.appendChild(element);
          }
        }
      }
    }
  }
}


  function savePreferences() {
    let preferences = {
      version: config.version,
    };

    for (let cookieType of optionalCookies) {
      let typePreference = {
        enabled: cookieType.enabled,
        providers: {},
      };

      for (let provider of cookieType.providers) {
        typePreference.providers[provider.key] = provider.enabled;
      }

      preferences[cookieType.key] = typePreference;
    }

    Cookies.set(config.preferencesCookieName, JSON.stringify(preferences), {
      sameSite: "strict",
      expires: 365,
    });
  }

  function acceptAll() {
    setAll(true);
    finish();
  }

  function denyAll() {
    setAll(false);
    finish();
  }

  function setAll(enabled) {
    for (let cookieType of optionalCookies) {
      cookieType.enabled = enabled;

      for (let provider of cookieType.providers) {
        provider.enabled = enabled;
      }
    }
  }

  function finish() {
    savePreferences();
    applyPreferences();
    close();
  }

  function showSettings() {
    settingsVisible = true;
  }

  function hideSettings() {
    settingsVisible = false;
  }

  function maximize() {
    document.body.style.overflow = "hidden";
    minimized = false;
  }

  function initialize() {
    if (cookiesAreEnabled()) {
      prepareMetaCookies();

      if (preferencesExist()) {
        loadPreferences();
        applyPreferences();
      } else {
        open();
      }

      const cookieButton = document.querySelector("#cookie-button");

      if (cookieButton) {
        cookieButton.addEventListener("click", () => {
          open();
          maximize();
        });
      }
    }
  }

  onMount(initialize);
</script>

{#if opened}
  {#if !minimized}
    <Backdrop>
      {#if !settingsVisible}
        <MainDialog
          {privacyUrl}
          {imprintUrl}
          on:accept={acceptAll}
          on:deny={denyAll}
          on:settings={showSettings}
        />
      {:else}
        <SettingsDialog
          {metaCookies}
          on:close={hideSettings}
          on:confirm={finish}
        />
      {/if}
    </Backdrop>
  {:else}
    <Overlay on:click={maximize} />
  {/if}
{/if}
