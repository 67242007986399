<script>
    import Dialog from "./Dialog.svelte";
    import Accordion from "./Accordion.svelte";
    import { createEventDispatcher } from "svelte";
    import Translation from "./Translation.svelte";

    const dispatch = createEventDispatcher();

    export let metaCookies;

    function handleClickOnClose() {
        dispatch("close");
    }

    function handleClickOnConfirm() {
        dispatch("confirm");
    }
</script>

<Dialog>
    <div slot="header" class="headline">
        <h2><Translation key="settings_dialog_header" /></h2>
        <button on:click={handleClickOnClose} class="close">
            <i class="far fa-times" />
        </button>
    </div>

    <div slot="body">
        <Translation key="settings_dialog_body" />
        {#each metaCookies as cookieType}
            <Accordion {cookieType} />
        {/each}
    </div>

    <div slot="footer" class="button-row">
        <button on:click={handleClickOnConfirm} class="btn btn-outline-danger">
            <Translation key="settings_dialog_confirm_button" />
        </button>
    </div>
</Dialog>

<style>
    .headline {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .headline > .close {
        border: none;
        background: transparent;
        min-width: 1em;
        min-height: 1em;
    }

    .headline > h2 {
        margin: 0;
    }

    .button-row {
        display: flex;
        justify-content: end;
        align-items: center;
    }
</style>
