const metaCookies = {
  "0": [
  {
    "title": "Essenziell",
    "required": true,
    "description": "<p>Notwendige Cookies helfen dabei, eine Webseite nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Die Webseite kann ohne diese Cookies nicht richtig funktionieren.</p>",
    "providers": [
      {
        "name": "Sielaff",
        "cookies": [
          {
            "name": "fe_typo_user",
            "lifespan": "Sitzungsende",
            "description": "Behält die Zustände des Benutzers bei allen Seitenanfragen bei."
          },
          {
            "name": "heartbytes",
            "lifespan": "Sitzungsende",
            "description": "Server Session Cookie"
          },
          {
            "name": "consent",
            "lifespan": "1 Jahr",
            "description": "Speichert Ihre Cookie-Einstellungen"
          }
        ]
      }
    ]
  },
  {
    "key": "statistics",
    "title": "Statistiken",
    "required": false,
    "description": "<p>Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie Besucher mit Webseiten interagieren, indem Informationen anonym gesammelt und gemeldet werden.</p>",
    "providers": [
      {
        "key": "matomo",
        "name": "Matomo",
        "embed": [
          {
            "parent": "head",
            "position": "end",
            "element": "script",
            "content": "var _paq=_paq||[];_paq.push([\"trackPageView\"]),_paq.push([\"enableLinkTracking\"]),function(){var e=(\"https:\"==document.location.protocol?\"https\":\"http\")+\"://piwik.sielaff.com/\";_paq.push([\"setTrackerUrl\",e+\"piwik.php\"]),_paq.push([\"setSiteId\",\"1\"]);var t=document,p=t.createElement(\"script\"),a=t.getElementsByTagName(\"script\")[0];p.type=\"text/javascript\",p.defer=!0,p.async=!0,p.src=e+\"piwik.js\",a.parentNode.insertBefore(p,a)}();"
          }
        ],
        "cookies": [
          {
            "name": "_pk_id",
            "lifespan": "13 Monate",
            "description": "Dient zum Speichern einiger Details zum Benutzer, z. B. der eindeutigen Besucher-ID"
          },
          {
            "name": "_pk_ref",
            "lifespan": "6 Monate",
            "description": "Der zum Speichern der Attributionsinformationen verwendete Referrer dient zum Besuch der ursprünglich Website"
          },
          {
            "name": "_pk_ses",
            "lifespan": "30 Minuten",
            "description": "Kurzlebiges Cookie, mit dem vorübergehend Daten für den Besuch gespeichert werden"
          },
          {
            "name": "_pk_cvar",
            "lifespan": "30 Minuten",
            "description": "Kurzlebiges Cookie, mit dem vorübergehend Daten für den Besuch gespeichert werden"
          },
          {
            "name": "_pk_hsr",
            "lifespan": "30 Minuten",
            "description": "Kurzlebiges Cookie, mit dem vorübergehend Daten für den Besuch gespeichert werden"
          },
        ]
      }
    ]
  }
  ],
  "1": [
  {
    "title": "Essentiel",
    "required": true,
    "description": "<p>Les cookies nécessaires facilitent l'exploitation d'une page d'accueil en permettant des fonctions de base telles que la navigation dans les pages et l'accès à des zones sécurisées du site. La page d'accueil ne peut pas fonctionner correctement sans ces cookies.</p>",
    "providers": [
      {
        "name": "Sielaff",
        "cookies": [
          {
            "name": "fe_typo_user",
            "lifespan": "Session",
            "description": "Conserve les états de l'utilisateur pour toutes les demandes de pages."
          },
          {
            "name": "heartbytes",
            "lifespan": "Session",
            "description": "Session de serveur Cookies"
          },
          {
            "name": "consent",
            "lifespan": "1 an",
            "description": "Enregistre vos paramètres de cookies"
          }
        ]
      }
    ]
  },
  {
    "key": "statistics",
    "title": "Statistiques",
    "required": false,
    "description": "<p>Les cookies statistiques aident les propriétaires de sites à comprendre comment les visiteurs interagissent avec les sites Internet en recueillant et communiquant des informations de manière anonyme.</p>",
    "providers": [
      {
        "key": "matomo",
        "name": "Matomo",
        "embed": [
          {
            "parent": "head",
            "position": "end",
            "element": "script",
            "content": "var _paq=_paq||[];_paq.push([\"trackPageView\"]),_paq.push([\"enableLinkTracking\"]),function(){var e=(\"https:\"==document.location.protocol?\"https\":\"http\")+\"://piwik.sielaff.com/\";_paq.push([\"setTrackerUrl\",e+\"piwik.php\"]),_paq.push([\"setSiteId\",\"1\"]);var t=document,p=t.createElement(\"script\"),a=t.getElementsByTagName(\"script\")[0];p.type=\"text/javascript\",p.defer=!0,p.async=!0,p.src=e+\"piwik.js\",a.parentNode.insertBefore(p,a)}();"
          }
        ],
        "cookies": [
          {
            "name": "_pk_id",
            "lifespan": "13 mois",
            "description": "Sert à enregistrer certains détails de l'utilisateur, comme p. ex. l'ID du visiteur."
          },
          {
            "name": "_pk_ref",
            "lifespan": "6 mois",
            "description": "La référence utilisée pour enregistrer les informations d'attribution servait à l'origine à la visite du site Internet."
          },
          {
            "name": "_pk_ses",
            "lifespan": "30 minutes",
            "description": "Cookies à courte durée de vie servant à enregistrer temporairement des données de visite."
          },
          {
            "name": "_pk_cvar",
            "lifespan": "30 minutes",
            "description": "Cookies à courte durée de vie servant à enregistrer temporairement des données de visite."
          },
          {
            "name": "_pk_hsr",
            "lifespan": "30 minutes",
            "description": "Cookies à courte durée de vie servant à enregistrer temporairement des données de visite."
          },
        ]
      }
    ]
  }
  ],
  "5": [
  {
    "title": "Essential",
    "required": true,
    "description": "<p>The required cookies help make a website usable by enabling basic functions such as page navigation and access to website secure areas. The website cannot function properly without these cookies.</p>",
    "providers": [
      {
        "name": "Sielaff",
        "cookies": [
          {
            "name": "fe_typo_user",
            "lifespan": "Session",
            "description": "Save the user status for all page queries."
          },
          {
            "name": "heartbytes",
            "lifespan": "Session",
            "description": "Server Session Cookie"
          },
          {
            "name": "consent",
            "lifespan": "1 year",
            "description": "Stores your cookie preferences"
          }
        ]
      }
    ]
  },
  {
    "key": "statistics",
    "title": "Statistics",
    "required": false,
    "description": "<p>Statistics cookies help website owners understand how visitors interact with websites by collecting and reporting information anonymously.</p>",
    "providers": [
      {
        "key": "matomo",
        "name": "Matomo",
        "embed": [
          {
            "parent": "head",
            "position": "end",
            "element": "script",
            "content": "var _paq=_paq||[];_paq.push([\"trackPageView\"]),_paq.push([\"enableLinkTracking\"]),function(){var e=(\"https:\"==document.location.protocol?\"https\":\"http\")+\"://piwik.sielaff.com/\";_paq.push([\"setTrackerUrl\",e+\"piwik.php\"]),_paq.push([\"setSiteId\",\"1\"]);var t=document,p=t.createElement(\"script\"),a=t.getElementsByTagName(\"script\")[0];p.type=\"text/javascript\",p.defer=!0,p.async=!0,p.src=e+\"piwik.js\",a.parentNode.insertBefore(p,a)}();"
          }
        ],
        "cookies": [
          {
            "name": "_pk_id",
            "lifespan": "13 months",
            "description": "Used to save some details relating to the user, e.g., the unique visitor ID."
          },
          {
            "name": "_pk_ref",
            "lifespan": "6 months",
            "description": "The referrer used for storing the assignment data was originally used to visit the website."
          },
          {
            "name": "_pk_ses",
            "lifespan": "30 minutes",
            "description": "Short-lived cookie that is used to temporarily store data for the visit"
          },
          {
            "name": "_pk_cvar",
            "lifespan": "30 minutes",
            "description": "Short-lived cookie that is used to temporarily store data for the visit"
          },
          {
            "name": "_pk_hsr",
            "lifespan": "30 minutes",
            "description": "Short-lived cookie that is used to temporarily store data for the visit"
          },
        ]
      }
    ]
  }
  ],
};

export default metaCookies;