<script>
    import Translation from "./Translation.svelte";

    export let cookie;
</script>

<div class="cookie-details">
    <table>
        <tr>
            <th>
                <Translation key="settings_dialog_cookie_name" />
            </th>
            <td>
                {cookie.name}
            </td>
        </tr>
        <tr>
            <th>
                <Translation key="settings_dialog_cookie_lifespan" />
            </th>
            <td>
                {cookie.lifespan}
            </td>
        </tr>
        <tr>
            <th>
                <Translation key="settings_dialog_cookie_description" />
            </th>
            <td>
                {cookie.description}
            </td>
        </tr>
    </table>
</div>

<style>
    .cookie-details {
        padding: 1rem;
        background-color: #ffffff;
        margin-bottom: 1rem;
    }

    .cookie-details > table {
        width: 100%;
        font-size: 0.8rem;
    }

    .cookie-details td,
    .cookie-details th {
        vertical-align: top;
    }

    .cookie-details th {
        width: 1%;
        white-space: nowrap;
        padding-right: 1rem;
    }
</style>
