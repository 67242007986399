<script>
    import Switch from "./Switch.svelte";
    import CookieDetails from "./CookieDetails.svelte";
    import Translation from "./Translation.svelte";

    export let provider;
    export let required;
</script>

<div class="provider-details">
    <div class="headline">
        <h3 class="h5">{provider.name}</h3>
        {#if required}
            <p><Translation key="settings_dialog_always_enabled" /></p>
        {:else}
            <Switch bind:enabled={provider.enabled} on:toggle />
        {/if}
    </div>
    {#each provider.cookies as cookie}
        <CookieDetails {cookie} />
    {/each}
</div>

<style>
    .provider-details {
        margin-bottom: 1rem;
    }

    .provider-details > .headline {
        display: flex;
        justify-content: space-between;
    }
</style>
