<script>
    import { createEventDispatcher } from "svelte";

    export let enabled;

    const dispatch = createEventDispatcher();

    function handleClick() {
        enabled = !enabled;
        dispatch("toggle", { enabled });
    }
</script>

<label class="switch" on:click|preventDefault={handleClick}>
    <input type="checkbox" checked={enabled} />
    <span class="slider round" />
</label>

<style>
    label.switch {
        position: relative;
        display: inline-block;
        width: 48px;
        height: 24px;
        margin: 0;
    }

    label.switch > input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #e3001b;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.25s;
        transition: 0.25s;
    }

    input:checked + .slider {
        background-color: #b3c758;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(24px);
        -ms-transform: translateX(24px);
        transform: translateX(24px);
    }

    .slider.round {
        border-radius: 24px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
</style>
