const translations = {
    "0": {
        "main_dialog_header": "Cookies und Datenschutz",
        "main_dialog_body": "Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.",
        "main_dialog_privacy": "Datenschutz",
        "main_dialog_imprint": "Impressum",
        "main_dialog_accept_button": "Akzeptieren",
        "main_dialog_deny_button": "Ablehnen",
        "main_dialog_settings_button": "Einstellungen",
        "settings_dialog_header": "Einstellungen",
        "settings_dialog_body": "<p>Wir setzen Google Maps und Matomo ein, um ihr Benutzererlebnis zu verbessern.</p>",
        "settings_dialog_confirm_button": "Bestätigen",
        "settings_dialog_always_enabled": "Immer aktiv",
        "settings_dialog_cookie_name": "Name",
        "settings_dialog_cookie_lifespan": "Dauer",
        "settings_dialog_cookie_description": "Beschreibung",
        "map_consent_text": "Mit dem Laden der Karte akzeptieren Sie die Datenschutzerklärung von Google.",
        "map_consent_button": "Einverstanden"
    },
    "1": {
        "main_dialog_header": "Cookies et protection des données",
        "main_dialog_body": "Nous utilisons des cookies pour personnaliser les contenus et affichages, pour proposer des fonctionnalités de médias sociaux et pour analyser les accès à notre site Internet. En outre, nous communiquons des informations concernant votre utilisation de notre site Internet à nos partenaires de médias sociaux, de publicité et d'analyses. Nos partenaires peuvent associer ces informations aux données supplémentaires que vous avez mises à votre disposition ou qu'ils ont recueillies dans le cadre de votre utilisation des services.",
        "main_dialog_privacy": "Protection des données",
        "main_dialog_imprint": "Mentions légales",
        "main_dialog_accept_button": "Accepter",
        "main_dialog_deny_button": "Décliner",
        "main_dialog_settings_button": "Configurer",
        "settings_dialog_header": "Configuration",
        "settings_dialog_body": "<p>Nous utilisons Google Maps et Matomo pour améliorer votre expérience utilisateur.</p>",
        "settings_dialog_confirm_button": "Confirmer",
        "settings_dialog_always_enabled": "Toujours activé",
        "settings_dialog_cookie_name": "Nom",
        "settings_dialog_cookie_lifespan": "Durée de vie",
        "settings_dialog_cookie_description": "Description",
        "map_consent_text": "En chargeant la carte, vous acceptez la politique de confidentialité de Google.",
        "map_consent_button": "D'accord"
    },
    "5": {
        "main_dialog_header": "Cookies and privacy",
        "main_dialog_body": "We use cookies to personalize content and advertisements, to be able to offer social media features and to analyze access to our website. We also share information about your use of our website with our social media, advertising and analytics partners. Our partners may combine this information with other data that you have provided to them or that they have collected as part of your use of the services.",
        "main_dialog_privacy": "Privacy",
        "main_dialog_imprint": "Imprint",
        "main_dialog_accept_button": "Accept",
        "main_dialog_deny_button": "Deny",
        "main_dialog_settings_button": "Settings",
        "settings_dialog_header": "Settings",
        "settings_dialog_body": "<p>We use Google Maps and Matomo to improve your user experience.</p>",
        "settings_dialog_confirm_button": "Confirm",
        "settings_dialog_always_enabled": "Always enabled",
        "settings_dialog_cookie_name": "Name",
        "settings_dialog_cookie_lifespan": "Lifespan",
        "settings_dialog_cookie_description": "Description",
        "map_consent_text": "By loading the map, you accept Google's privacy policy.",
        "map_consent_button": "Agree"
    }
}

export default translations;