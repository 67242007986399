<div class="dialog">
    <div class="header">
        <slot name="header" />
    </div>
    <div class="body">
        <slot name="body" />
    </div>
    <div class="footer">
        <slot name="footer" />
    </div>
</div>

<style>
    .dialog {
        background-color: #ffffff;
        height: auto;
        max-height: 90vh;
        width: 90vw;
        max-width: 640px;
        overflow: hidden;
        position: relative;
    }

    .dialog > .header {
        padding: 1rem;
        border-bottom: 1px solid #cacaca;
    }

    .dialog > .body {
        padding: 1rem;
        overflow: auto;
        height: auto;
        max-height: 50vh;
    }

    .dialog > .footer {
        padding: 1rem;
        border-top: 1px solid #cacaca;
    }
</style>
