import CookieConsent from './CookieConsent.svelte';
import MapConsent from './MapConsent.svelte';

const element = document.querySelector('#cookie-consent');

const cookieConsent = new CookieConsent({
	target: element,
	props: element.dataset
});

const maps = document.querySelectorAll('div[data-content]');
const mapConsents = [];

for (let map of maps) {
	mapConsents.push(new MapConsent({
		target: map,
		props: map.dataset
	}));
}

export { cookieConsent, mapConsents }; 