<div class="backdrop">
    <slot />
</div>

<style>
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        z-index: 1051;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
