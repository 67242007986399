<script>
    import ProviderDetails from "./ProviderDetails.svelte";
    import Switch from "./Switch.svelte";
    import Translation from "./Translation.svelte";

    export let cookieType;

    let collapsed = true;

    function collapse() {
        collapsed = !collapsed;
    }

    function handleTypeToggle() {
        for (let provider of cookieType.providers) {
            provider.enabled = cookieType.enabled;
        }
    }

    function handleProviderToggle() {
        for (let provider of cookieType.providers) {
            if (provider.enabled === false) {
                cookieType.enabled = false;
                return;
            }
        }
        cookieType.enabled = true;
    }
</script>

<div class="accordion">
    <div class="head">
        <p on:click={collapse} class="title">
            <span class="operator">
                {#if collapsed}
                    +
                {:else}
                    -
                {/if}
            </span>
            {cookieType.title}
        </p>
        {#if cookieType.required}
            <p><Translation key="settings_dialog_always_enabled" /></p>
        {:else}
            <Switch
                bind:enabled={cookieType.enabled}
                on:toggle={handleTypeToggle}
            />
        {/if}
    </div>
    {#if !collapsed}
        <div class="body">
            {@html cookieType.description}
            {#each cookieType.providers as provider}
                <ProviderDetails
                    {provider}
                    required={cookieType.required}
                    on:toggle={handleProviderToggle}
                />
            {/each}
        </div>
    {/if}
</div>

<style>
    .accordion {
        border: 1px solid #cacaca;
        background-color: #f8f5f5;
        margin-bottom: 0.5rem;
    }

    .accordion > .head {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        background-color: #ffffff;
    }

    .accordion > .head > .title {
        cursor: pointer;
    }

    .accordion > .body {
        padding: 1rem;
    }

    .accordion p {
        margin: 0;
    }

    .operator {
        margin-right: 1rem;
        font-family: monospace;
        font-weight: bold;
    }
</style>
