<aside>
    <button on:click class="text-danger">
        <i class="fas fa-cookie-bite"></i>
    </button>
</aside>

<style>
    aside {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 1051;
    }

    aside > button {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-color: #ffffff;
        box-shadow: 0 0 0.33rem 0 rgba(0, 0, 0, 0.75);
        font-size: 2.5rem;
        margin: 1rem;
    }
</style>